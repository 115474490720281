<template>
    <div>
      <div class="mainPic">
          <div class="bcon d-none d-sm-flex">
              <div class="borders">
                  <!-- <div class="tl"><img src="/assets/img/borders/tl.svg"/></div>
                  <div class="tr"><img src="/assets/img/borders/tr.svg"/></div>
                  <div class="bl"><img src="/assets/img/borders/bl.svg"/></div>
                  <div class="br"><img src="/assets/img/borders/br.svg"/></div> -->
              </div>
              <div class="flora"></div>
              <div class="Con">
                  <div class="op">
                      <p class="tl ta" >{{ $t("musfah") }}</p>
                      <p class="tl ta" >{{ $t("ataba") }}</p>
                      <p class="tl ta" >{{ $t("center") }}</p>
                      <div class="btnCon">
                          <v-btn width="180" height="50" class="ma-2 tl" style="align-self:center !important;" color="#145C5E">
                              <p class="btnT tl">{{ $t('downloadQ') }}</p>
                              <span class="btnI">
                                  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7.12817 0.977657C7.13591 0.465542 7.55134 0.0139912 8.0202 0.00402486C8.67322 -0.00134162 9.32695 -0.00134162 9.97998 0.00402486C10.4495 0.0132245 10.8636 0.466309 10.872 0.978424C10.8783 2.56077 10.8727 4.14311 10.8748 5.72546C11.5883 5.73159 12.3032 5.71319 13.0167 5.73389C13.4567 5.77222 13.6634 6.44303 13.3372 6.76732C12.0326 8.26227 10.7244 9.75415 9.41763 11.2468C9.20745 11.5128 8.79413 11.5144 8.58465 11.2483C7.26806 9.74188 5.94584 8.24157 4.63486 6.73052C4.33963 6.39473 4.56879 5.75996 4.99406 5.73312C5.70403 5.71472 6.41469 5.73082 7.12536 5.72546C7.12747 4.14311 7.12185 2.56 7.12817 0.977657Z" fill="white"/>
                                  <path d="M0.950766 13.1418C1.09838 13.0935 1.25373 13.0851 1.40697 13.0843C6.35213 13.0858 11.2973 13.0843 16.2424 13.0851C16.5433 13.0897 16.8575 13.0536 17.1443 13.1763C17.6286 13.3672 17.9822 13.8954 17.9942 14.4581C18.0026 14.8245 18.0237 15.2132 17.8648 15.549C17.6511 16.042 17.1626 16.3693 16.6621 16.3555C11.5532 16.3563 6.44421 16.3563 1.33457 16.3555C0.819317 16.3693 0.318123 16.0182 0.114974 15.5022C-0.0249099 15.1757 -0.00100979 14.8061 0.00601957 14.4558C0.0172666 13.8548 0.422158 13.302 0.950766 13.1418Z" fill="white"/>
                                  </svg>
                              </span>
                          </v-btn>
                          <v-btn width="180" height="50" class="ma-2 tl" style="align-self:center !important;" color="#145C5E">
                              <p class="btnT tl">{{ $t('readQ') }}</p>
                              <span class="btnI">
                                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M2.11802 0.880978C2.0899 0.490474 2.3851 0.123401 2.74356 0.123401C3.9384 0.131211 5.1473 0.342083 6.23672 0.920028C7.05906 1.34958 7.78299 2.01344 8.29607 2.84912C8.41556 3.02094 8.52099 3.224 8.50693 3.45049C8.50693 7.1837 8.50693 10.9091 8.50693 14.6423C7.4386 13.4005 5.95558 12.6898 4.43743 12.4321C3.87514 12.3227 3.30584 12.2915 2.74356 12.2759C2.39213 12.2759 2.09693 11.901 2.12505 11.5105C2.11802 7.96471 2.11802 4.42675 2.11802 0.880978Z" fill="white"/>
                                  <path d="M12.6959 0.521723C13.5253 0.240562 14.3968 0.123411 15.2613 0.123411C15.6127 0.115601 15.915 0.490483 15.8869 0.880985C15.8869 4.42674 15.8869 7.9725 15.8869 11.5104C15.915 11.901 15.6198 12.2758 15.2684 12.2758C14.0946 12.2993 12.9068 12.4789 11.8033 12.9631C10.9388 13.338 10.1446 13.9081 9.50497 14.6423C9.50497 10.9091 9.50497 7.18369 9.50497 3.45049C9.49091 3.13809 9.68771 2.90378 9.83531 2.66167C10.5311 1.61513 11.5714 0.896605 12.6959 0.521723Z" fill="white"/>
                                  <path d="M0.508492 2.61486C0.70529 2.58362 0.909118 2.59924 1.11294 2.59924C1.11294 5.56705 1.11294 8.53486 1.11294 11.5105C1.09889 12.2056 1.45031 12.885 2.0126 13.1896C2.47648 13.4708 3.0247 13.3693 3.53076 13.4317C5.00675 13.5645 6.51085 14.08 7.6073 15.2359C5.3652 14.6423 3.01065 14.8453 0.782604 15.3842C0.508492 15.4545 0.206265 15.2983 0.0797518 15.0172C-0.011619 14.8297 0.00946658 14.611 0.00243805 14.4002C0.00243805 10.7295 0.00243805 7.05876 0.00243805 3.39586C-0.025676 3.0366 0.192208 2.68515 0.508492 2.61486Z" fill="white"/>
                                  <path d="M16.8849 2.59137C17.1309 2.60699 17.398 2.54451 17.637 2.66166C17.8759 2.78662 18.0165 3.0834 17.9954 3.37237C17.9954 7.12902 17.9954 10.8779 17.9954 14.6267C18.0235 15.0172 17.7143 15.3921 17.3558 15.3843C17.0747 15.3608 16.8006 15.2593 16.5265 15.2125C14.5093 14.7985 12.4007 14.6892 10.3906 15.2203C11.487 14.0644 12.9981 13.5489 14.4741 13.4161C14.9732 13.3537 15.5214 13.4552 15.9782 13.174C16.5335 12.8694 16.8919 12.1821 16.8779 11.4949C16.8919 8.53484 16.8849 5.56701 16.8849 2.59137Z" fill="white"/>
                                  </svg>
                              </span>
                          </v-btn>
                      </div>
                  </div>
                  <div class="img"></div>
              </div>
          </div>
          <div class="mcon d-flex d-sm-none">
              <div class="borders">
                  <div class="tl"><img src="/assets/img/borders/mob/tl.svg"/></div>
                  <div class="tr"><img src="/assets/img/borders/mob/tr.svg"/></div>
                  <div class="bl"><img src="/assets/img/borders/mob/bl.svg"/></div>
                  <div class="br"><img src="/assets/img/borders/mob/br.svg"/></div>
              </div>
              <div class="flora"></div>
              <div class="Con">
                  <div class="op">
                      <p class="tl ta" >{{ $t("musfah") }}</p>
                      <p class="tl ta" >{{ $t("ataba") }}</p>
                      <p class="tl ta" >{{ $t("center") }}</p>
                      <div class="btnCon">
                          <v-btn width="250" height="50" class="ma-2 tl" style="align-self:center !important;" color="#145C5E">
                              <p class="btnT tl">{{ $t('readQ') }}</p>
                              <span class="btnI">
                                  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M2.11802 0.880978C2.0899 0.490474 2.3851 0.123401 2.74356 0.123401C3.9384 0.131211 5.1473 0.342083 6.23672 0.920028C7.05906 1.34958 7.78299 2.01344 8.29607 2.84912C8.41556 3.02094 8.52099 3.224 8.50693 3.45049C8.50693 7.1837 8.50693 10.9091 8.50693 14.6423C7.4386 13.4005 5.95558 12.6898 4.43743 12.4321C3.87514 12.3227 3.30584 12.2915 2.74356 12.2759C2.39213 12.2759 2.09693 11.901 2.12505 11.5105C2.11802 7.96471 2.11802 4.42675 2.11802 0.880978Z" fill="white"/>
                                  <path d="M12.6959 0.521723C13.5253 0.240562 14.3968 0.123411 15.2613 0.123411C15.6127 0.115601 15.915 0.490483 15.8869 0.880985C15.8869 4.42674 15.8869 7.9725 15.8869 11.5104C15.915 11.901 15.6198 12.2758 15.2684 12.2758C14.0946 12.2993 12.9068 12.4789 11.8033 12.9631C10.9388 13.338 10.1446 13.9081 9.50497 14.6423C9.50497 10.9091 9.50497 7.18369 9.50497 3.45049C9.49091 3.13809 9.68771 2.90378 9.83531 2.66167C10.5311 1.61513 11.5714 0.896605 12.6959 0.521723Z" fill="white"/>
                                  <path d="M0.508492 2.61486C0.70529 2.58362 0.909118 2.59924 1.11294 2.59924C1.11294 5.56705 1.11294 8.53486 1.11294 11.5105C1.09889 12.2056 1.45031 12.885 2.0126 13.1896C2.47648 13.4708 3.0247 13.3693 3.53076 13.4317C5.00675 13.5645 6.51085 14.08 7.6073 15.2359C5.3652 14.6423 3.01065 14.8453 0.782604 15.3842C0.508492 15.4545 0.206265 15.2983 0.0797518 15.0172C-0.011619 14.8297 0.00946658 14.611 0.00243805 14.4002C0.00243805 10.7295 0.00243805 7.05876 0.00243805 3.39586C-0.025676 3.0366 0.192208 2.68515 0.508492 2.61486Z" fill="white"/>
                                  <path d="M16.8849 2.59137C17.1309 2.60699 17.398 2.54451 17.637 2.66166C17.8759 2.78662 18.0165 3.0834 17.9954 3.37237C17.9954 7.12902 17.9954 10.8779 17.9954 14.6267C18.0235 15.0172 17.7143 15.3921 17.3558 15.3843C17.0747 15.3608 16.8006 15.2593 16.5265 15.2125C14.5093 14.7985 12.4007 14.6892 10.3906 15.2203C11.487 14.0644 12.9981 13.5489 14.4741 13.4161C14.9732 13.3537 15.5214 13.4552 15.9782 13.174C16.5335 12.8694 16.8919 12.1821 16.8779 11.4949C16.8919 8.53484 16.8849 5.56701 16.8849 2.59137Z" fill="white"/>
                                  </svg>
                              </span>
                          </v-btn>
                          <v-btn width="250" height="50" class="ma-2 tl" style="align-self:center !important;" color="#145C5E">
                              <p class="btnT tl">{{ $t('downloadQ') }}</p>
                              <span class="btnI">
                                  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M7.12817 0.977657C7.13591 0.465542 7.55134 0.0139912 8.0202 0.00402486C8.67322 -0.00134162 9.32695 -0.00134162 9.97998 0.00402486C10.4495 0.0132245 10.8636 0.466309 10.872 0.978424C10.8783 2.56077 10.8727 4.14311 10.8748 5.72546C11.5883 5.73159 12.3032 5.71319 13.0167 5.73389C13.4567 5.77222 13.6634 6.44303 13.3372 6.76732C12.0326 8.26227 10.7244 9.75415 9.41763 11.2468C9.20745 11.5128 8.79413 11.5144 8.58465 11.2483C7.26806 9.74188 5.94584 8.24157 4.63486 6.73052C4.33963 6.39473 4.56879 5.75996 4.99406 5.73312C5.70403 5.71472 6.41469 5.73082 7.12536 5.72546C7.12747 4.14311 7.12185 2.56 7.12817 0.977657Z" fill="white"/>
                                  <path d="M0.950766 13.1418C1.09838 13.0935 1.25373 13.0851 1.40697 13.0843C6.35213 13.0858 11.2973 13.0843 16.2424 13.0851C16.5433 13.0897 16.8575 13.0536 17.1443 13.1763C17.6286 13.3672 17.9822 13.8954 17.9942 14.4581C18.0026 14.8245 18.0237 15.2132 17.8648 15.549C17.6511 16.042 17.1626 16.3693 16.6621 16.3555C11.5532 16.3563 6.44421 16.3563 1.33457 16.3555C0.819317 16.3693 0.318123 16.0182 0.114974 15.5022C-0.0249099 15.1757 -0.00100979 14.8061 0.00601957 14.4558C0.0172666 13.8548 0.422158 13.302 0.950766 13.1418Z" fill="white"/>
                                  </svg>
                              </span>
                          </v-btn>
                      </div>
                  </div>
                  <div class="img"></div>
              </div>
          </div>
      </div>
      <Home />
    </div>
</template>

<script>

  export default {

    components: {
      Home: () => import("../../components/core/main.vue"),
    },

    data: () => ({
      expandOnHover: false
    }),
    mounted() {  
      document.title =this.$t("site_title");
    },
  };
</script>

